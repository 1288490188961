@font-face {
  font-family: 'Century Gothic';
  src: url('fonts/centuryGothic/CenturyGothic.woff2') format('woff2'),
      url('fonts/centuryGothic/CenturyGothic.woff') format('woff'),
      url('fonts/centuryGothic/CenturyGothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "Century Gothic";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

a {
  text-decoration: none;
}



.map_style {

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ------------- ARRM Restaurants responsive design and grid ------------------- */
.restaurants_grid {
  display: grid;
  grid-template-columns: repeat(4, 300px);
  grid-gap: 10px;
  row-gap: 8px;
}

.restaurants_grid_item {
  position: relative;
  overflow: hidden;
  cursor: pointer;

}

.restaurants_grid_item img {
  width: 100%;
  height: auto;
  transition: 300ms;
}

.restaurants_grid_item img:hover {
  scale: 1.1;
}

@media (max-width: 1450px) {
  .restaurants_grid {
    grid-template-columns: repeat(3, 300px);
  }

  .map_style {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 1100px) {
  .restaurants_grid {
    grid-template-columns: repeat(2, 300px);
  }
}

@media (max-width: 750px) {
  .restaurants_grid {
    grid-template-columns: repeat(1, 300px);
  }
}

/* --------------------------------------------------------------------- */

/* ----------------------------- WHITE COFFEE SPOT STYLES ------------------*/
.wcs_navBar {
  position: relative;
  height: 40px;
  display: flex;
  gap: 30px;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-top: -40px;
  list-style-type: none;
  z-index: 1;
}

.wcs_navBar li a {
  cursor: pointer;
  color: black;
  transition: 300ms;
}

.wcs_navBar  li.active a {
  color: #87D6ED;
}


.wcs_wrapper_grid {
  cursor: pointer;
  display: grid;
  max-width: 1108px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto auto;
  margin-top: 30px;
  gap: 10px;
}


.wcs_wrapper_grid_item {
  position: relative;

}




.wcs_grid_box1 {
  position: relative;
  width: 100%;
}

 .wcs_grid_box2 {
  position:relative;
  width: 100%;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
} 

.wcs_grid_box3 {
  width: 100%;
  position: relative;
  grid-column-start: 3;
  grid-column-end: 5;
}

.wcs_grid_box9 {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 5;
}

@media (max-width: 600px) {
  .wcs_navBar {
    font-size: 11px;
  }
}

.close_full_image {
  position: absolute;
  inset:0;
  cursor: pointer;
  color: white;
  left: 90%;
  padding: 30px 0px;
  z-index:100;
}

/* .wcs_grid_box4 {
  grid-row-start: 2;
} */
